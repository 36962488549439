import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export class LocalTranslateLoader extends TranslateLoader {
  constructor(
    private translations: { [lang: string]: Record<string, unknown> }
  ) {
    super();
  }

  getTranslation(lang: string): Observable<Record<string, unknown>> {
    return of(this.translations[lang.split('-')[0]] || {});
  }
}

export function LoaderFactory(translations: {
  [lang: string]: Record<string, unknown>;
}): TranslateLoader {
  return new LocalTranslateLoader(translations);
}
